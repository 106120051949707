* {
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Roboto", sans-serif;
}

html,
body {
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

.first {
  position: fixed;
  display: none;
}

.allmenu {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.menu {
  position: relative;
  width: 20vw;
  margin: 2vw;
}

.clicked {
  pointer-events: none;
}

.back {
  position: fixed;
  width: 12vw;
  left: 2vw;
  top: 1vw;
  z-index: 2;
}

.firstmenu {
  position: fixed;
  right: 1.8vw;
  top: 1vw;
}
.fm {
  width: 2rem;
  height: auto;
  display: block;
  margin-top: 0.8rem;
}

/*/////////////////// intro /////////////////*/

.intro {
  position: fixed;
  text-align: center;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.introback {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: black;
  z-index: 5;
  pointer-events: none;
}

.introwrap {
  position: absolute;
  width: 76vw;
  height: 44vw;
  background: rgba(255, 255, 255, 0.8);
  left: 50%;
  top: 46%;
  border-radius: 15px 15px;
  z-index: 10;
  transform: translate(-50%, -50%);
}
.intro p {
  position: relative;
  opacity: 1;
  top: 18%;
}

.big {
  position: relative;
  font-size: 4.2vw;
  /* top: 15%; */
  font-weight: 400;
}
.small {
  position: relative;
  font-size: 3vw;
  font-weight: 300;
  margin: 0.15em;
}
.one {
  position: absolute;
  width: 76vw;
  height: 0.5px;
  background-color: #c4c4c4;
  top: 75%;

  z-index: 1;
}
.two {
  position: absolute;
  width: 0.5px;
  height: 25%;
  background-color: #c4c4c4;
  bottom: 0%;
  left: 50%;
  z-index: 1;
}
.no {
  position: absolute;
  font-size: 4.2vw;
  color: #007aff;
  bottom: 0%;
  left: 0%;
  text-align: center;
  line-height: 280%;
  width: 50%;
  height: 25%;
}
.ok {
  position: absolute;
  font-size: 4.2vw;
  color: #007aff;
  text-align: center;
  line-height: 280%;
  bottom: 0%;
  right: 0%;
  width: 50%;
  height: 25%;
  /* z-index: 10; */
}

/*/////////////////// menu /////////////////*/
.candle {
  position: fixed;
  display: none;
  background: black;
  width: 100%;
  height: 100%;
  /* height: calc(var(--vh, 1vh) * 100); */
}

.mistletoe {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
}

.snowman {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
}

.cookie {
  position: fixed;
  width: 100%;
  height: 100%;
  display: none;
  height: 100%;
}

.santa {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
}

.tree {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
}

.feast {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
}

.present {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  /* display: block; */
}

/*////////////////candle /////////////*/
.candlelight1 {
  position: fixed;
  left: 27%;
  bottom: 67vw;
  transform: translate(-50%, -50%);
  width: 10vw;
  mix-blend-mode: screen;
  z-index: 1;
  pointer-events: none;
  transform-origin: 50% 100%;
}

.candlelight2 {
  position: fixed;
  left: 50%;
  bottom: 118vw;
  transform: translate(-50%, -50%);
  width: 10vw;
  mix-blend-mode: screen;
  z-index: 1;
  pointer-events: none;
  transform-origin: 50% 100%;
}
.candlelight3 {
  position: fixed;
  left: 72%;
  bottom: 100vw;
  transform: translate(-50%);
  width: 10vw;
  mix-blend-mode: screen;
  z-index: 1;
  pointer-events: none;
  transform-origin: 50% 100%;
}
.candlebody {
  position: fixed;
  left: 50%;
  bottom: 15vw;
  transform: translate(-50%);
  width: 73vw;
  /* pointer-events: none; */
}

.candleout1 {
  position: fixed;
  left: 27%;
  bottom: 67vw;
  transform: translate(-50%, -50%);
  width: 10vw;
  mix-blend-mode: screen;
  z-index: 1;
  display: none;
  pointer-events: none;
}

.candleout2 {
  position: fixed;
  left: 50%;
  bottom: 118vw;
  transform: translate(-50%, -50%);
  width: 10vw;
  mix-blend-mode: screen;
  z-index: 1;
  display: none;
  pointer-events: none;
}

.candleout3 {
  position: fixed;
  left: 72%;
  bottom: 100vw;
  transform: translate(-50%);
  width: 10vw;
  mix-blend-mode: screen;
  z-index: 1;
  display: none;
  pointer-events: none;
}

/*///////////////// mistletoe ///////////////////*/
.poster {
  position: fixed;
  height: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: -3;
  pointer-events: none;
}

.mis {
  position: fixed;
  width: 18vw;
  left: 50%;
  transform: translate(-50%);
  top: 2.5vh;
}

.lips {
  position: fixed;
  width: 50vw;
  height: 50vw;
  left: 50%;
  transform: translate(-50%);
  top: 45%;
  background-color: red;
  opacity: 0;
  border-radius: 50%;
}
.kiss1 {
  position: fixed;
  height: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
  pointer-events: none;
  display: block;
}
.kiss2 {
  position: fixed;
  height: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: -2;
  pointer-events: none;
}

/*////////////////snow//////////////////*/
.snowText {
  position: fixed;
  opacity: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.snowbackground {
  position: fixed;
  height: 100vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
  pointer-events: none;
}
.innershadow {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  box-shadow: inset 0 0 10vw 4vw white;
}

/*////////////////cookie////////////////*/

.pan {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 102%;
}
.score {
  position: fixed;
  right: 2vw;
  top: 2vw;
  display: inline;
}
.cookieScore {
  width: 2rem;
  display: inline;
}
.scorejs {
  position: relative;
  top: -0.35rem;
  display: inline;
  margin: 0.5rem;
  font-size: 1.3rem;
}
.timer {
  position: fixed;
  left: 50%;
  top: 0.7rem;
  transform: translate(-50%);
  font-size: 1.3rem;
  /* background: white; */
}
.cookieDone {
  display: none;
  z-index: 10;
}

/*//////////santa////////////////*/
.santa1 {
  position: absolute;
  width: 70%;
  max-width: 500px;
  bottom: 8%;
  left: -10%;
  pointer-events: none;
}
.santa2 {
  position: absolute;
  width: 70%;
  max-width: 500px;
  bottom: 8%;
  left: 0;
  display: none;
  pointer-events: none;
}
.santa3 {
  position: absolute;
  pointer-events: none;
  width: 150%;
  bottom: 10%;
  left: 0;
  display: none;
}
.santaback {
  position: absolute;
  left: 0;
  height: 100%;
  pointer-events: none;
}
.wall {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

/*///////////tree/////////////*/
.tree1 {
  position: fixed;
  height: 100%;
  z-index: -1;
  left: 50%;
  transform: translate(-50%);
}
.tree2 {
  position: fixed;
  pointer-events: none;
}
.treemenu {
  position: fixed;
  right: 1.8vw;
  top: 1vw;
}
.tm {
  width: 2rem;
  height: auto;
  display: block;
  margin-top: 0.8rem;
  transition: 0.2s;
  opacity: 0.4;
}
.clickedt {
  pointer-events: none;
  opacity: 1;
}

.reset {
  width: 2rem;
  height: auto;
  display: block;
  margin-top: 0.8rem;
}

/*///////////feast/////////////*/

.player {
  position: fixed;
  width: 55vw;
  height: auto;
  bottom: 0;
  left: 0;
}
.food {
  position: fixed;
  width: 12vw;
  height: auto;
  pointer-events: none;
}
.fscore {
  position: fixed;
  right: 2vw;
  top: 2vw;
  display: inline;
  pointer-events: none;
}
.feastScore {
  position: relative;
  width: 2rem;
  display: inline;
  pointer-events: none;
}
.fscorejs {
  position: relative;
  top: -0.35rem;
  margin: 0.5rem;
  display: inline;
  font-size: 1.3rem;
}

/*///////////present/////////////*/
.presentimg {
  position: fixed;
}

/*///////////transition/////////////*/
.transition {
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  pointer-events: none;
  z-index: 10;
  pointer-events: none;
}
.transition li {
  transform: scaleY(1);
  width: 25vw;
  list-style-type: none;
}

/*///////////feastdone/////////////*/
.done {
  position: fixed;
  text-align: center;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: none;
}

.over {
  position: fixed;
  text-align: center;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: none;
}

.doneback {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: black;
  z-index: -1;
  pointer-events: none;
}

.donewrap {
  position: absolute;
  width: 76vw;
  height: 44vw;
  background: rgba(255, 255, 255, 0.8);
  left: 50%;
  top: 46%;
  border-radius: 15px 15px;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.done p {
  position: relative;
  opacity: 1;
  top: 18%;
}

.big {
  position: relative;
  font-size: 4.2vw;
  /* top: 15%; */
  font-weight: 400;
}
.small {
  position: relative;
  font-size: 3vw;
  font-weight: 300;
  margin: 0.15em;
}
.one {
  position: absolute;
  width: 76vw;
  height: 0.5px;
  background-color: #c4c4c4;
  top: 75%;

  z-index: 1;
}

.doneb {
  position: absolute;
  font-size: 4.2vw;
  color: #007aff;
  text-align: center;
  line-height: 280%;
  bottom: 0%;
  right: 0%;
  width: 100%;
  height: 25%;
}

/*/////////Destop////////////*/

.desktop {
  z-index: 15;
  display: none;
}

.desktoptext {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: inherit;
  font-size: 1rem;
}

.desktopintro {
  position: fixed;
  text-align: center;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.desktopintroback {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: black;
  z-index: 5;
  pointer-events: none;
}

.desktopintrowrap {
  position: absolute;
  width: 400px;
  height: 180px;
  background: rgba(255, 255, 255, 0.8);
  left: 50%;
  top: 45%;
  border-radius: 15px 15px;
  z-index: 10;
  transform: translate(-50%, -50%);
}
.desktopintro p {
  position: relative;
  font-size: 20px;
  opacity: 1;
  top: 0%;
}

.desktopclose {
  position: absolute;
  font-size: 20px;
  color: #007aff;
  text-align: center;
  line-height: 280%;
  bottom: 0%;
  right: 0%;
  width: 100%;
  height: 30%;
}
.desktopone {
  position: absolute;
  width: 400px;
  height: 0.5px;
  background-color: #c4c4c4;
  top: 72%;
  z-index: 1;
}
